import React, { FunctionComponent, PropsWithChildren } from "react";
import ReactGA from "react-ga";

interface IProps {
    category: string;
    action: string;
    label: string;
    value?: number;
    nonInteraction?: boolean;
}

export const GoogleAnalyticsEvent: FunctionComponent<IProps> = (props: PropsWithChildren<IProps>) => {
    ReactGA.event(props);

    return <></>;
};
