import React from "react";
import { Layout } from "../components/Layout";
import { SEO } from "../components/SEO";
import { ShowReel } from "../components/Showreel";
import { GuardState } from "../renderProps/ClientAuthenticationGuard";
import { CookieGuard } from "../renderProps/CookieGuard";

const IndexPage = () => {
    return (
        <CookieGuard
            render={({ authenticated }) => {
                if (authenticated === GuardState.Failed) {
                    window.location.href = "/authenticate";
                    return <></>;
                } else {
                    return (
                        <Layout>
                            <SEO title="Home" meta={[]} lang={"en-au"} />
                            <h2>2019 Showreel</h2>
                            <ShowReel />
                        </Layout>
                    );
                }
            }}
        />
    );
};

export default IndexPage;
