import React, { FunctionComponent } from "react";
import { GoogleAnalyticsEvent } from "./GoogleAnalyticsEvent";
import ReactGA from "react-ga";

interface IProps {}

export const ShowReel: FunctionComponent<IProps> = () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const mp4Path = require("../videos/showreel.mp4");
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const posterPath = require("../images/poster_1280x720.jpg");

    const onPlay = (event: React.SyntheticEvent) => {
        ReactGA.event({
            category: "video",
            action: "played",
            label: "Video Played",
        });
    };

    return (
        <div className={"showreel"}>
            <GoogleAnalyticsEvent category={"video"} action={"loaded"} nonInteraction={true} label={"Video Loaded"} />
            <video controls={true} onPlay={onPlay} poster={posterPath}>
                <source src={mp4Path} type={"video/mp4"} />
            </video>
        </div>
    );
};
